/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/easy-autocomplete@1.3.5/dist/jquery.easy-autocomplete.min.js
 * - /npm/jquery-lazy@1.7.11/jquery.lazy.min.js
 * - /npm/jquery-lazy@1.7.11/jquery.lazy.plugins.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
